import React, { useEffect } from "react"
import SEO from "../components/Seo"
import { connect } from "react-redux"
import Layout from "../components/Layout"
import { StyledContainer, StyledButton, StyledBlock, StyledH2, StyledText } from "../components/styled"
import styled from "styled-components"

const StyledJoinPage: any = styled.div`
	img {
		margin-bottom: 24px;
	}

	.layout {
		margin-bottom: 30px;
	}

	.left ${StyledText} {
		margin-bottom: 24px;
	}

	@media (min-width: 1024px) {
		.layout {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 20px;
			margin-bottom: 70px;
		}

		${StyledH2} {
			font-size: 50px;
		}
	}
`

const RegisterPage = ({ toggleRegisterForm, defaultHeaderColor, changeHeaderColor }: any) => {
	useEffect(() => {
		changeHeaderColor(defaultHeaderColor)
	}, [])
	return (
		<Layout>
			<SEO title="Register" />
			<StyledJoinPage>
				<StyledBlock subPage>
					<StyledContainer>
						<div className="layout">
							<div className="left">
								<StyledText uppercase small>
									Welcome to the raise application
								</StyledText>
								<StyledH2>Help us to create the Algorithm of connection.</StyledH2>
							</div>
							<div className="right">
								<div>
									<img src="http://placehold.it/600x400" alt="" />
								</div>
								<StyledText uppercase small>
									This is why we need your information so detailed.
								</StyledText>
							</div>
						</div>
						<StyledButton dark centered onClick={toggleRegisterForm}>
							Start
						</StyledButton>
					</StyledContainer>
				</StyledBlock>
			</StyledJoinPage>
		</Layout>
	)
}

const mapStateToProps = ({ defaultHeaderColor }: any) => ({ defaultHeaderColor })

const mapDispatchToProps = (dispatch: any) => {
	return {
		toggleRegisterForm: () => dispatch({ type: `TOGGLE_REGISTER_FORM` }),
		changeHeaderColor: (payload: boolean) => dispatch({ type: `CHANGE_HEADER_COLOR`, payload }),
	}
}

const ConnectedRegisterPage = connect(mapStateToProps, mapDispatchToProps)(RegisterPage)

export default ConnectedRegisterPage
